import "./bootstrap";
import "../sass/www.css";
import "./geolocation";
import "./utilities/sentry";
import Vue from "vue";
import { i18n } from "./i18n";
import { formatDate, generateSrcset } from "./utilities";

// Define global components
const globalFiles = import.meta.glob("./global/*.vue");
for (const path in globalFiles) {
  Vue.component(path.split("/").pop().split(".")[0], globalFiles[path]);
}

// Define www-only components
const wwwFiles = import.meta.glob("./www/**/*.vue");
for (const path in wwwFiles) {
  Vue.component(path.split("/").pop().split(".")[0], wwwFiles[path]);
}

Vue.component("category-icon", () => import("./components/CategoryIcon.vue"));
Vue.component("image-cropper", () => import("./components/ImageCropper.vue"));
Vue.component("profile-card", () => import("./components/ProfileCard.vue"));
Vue.component("side-menu", () => import("./components/SideMenu.vue"));
Vue.component("toggle-visibility-button", () => import("./components/ToggleVisibilityButton.vue"));

Vue.mixin({ methods: { formatDate, generateSrcset } });

const el = document.getElementById("app");
new Vue({ i18n }).$mount(el);
